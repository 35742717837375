<template>
  <div>
    <el-table
      class="report-rss-table"
      size="mini"
      v-loading="loading"
      border
      empty-text="Нет данных"
      :data="data.procedure_users"
      :row-key="(row) => row.user_id"
      header-row-class-name="report-rss-header"
      :cell-class-name="getCellClass"
      @sort-change="sortChanged($event, loadProcedureReport)"
    >
      <el-table-column label="Компенсационный менеджер сотрудника" prop="chief.full_name"></el-table-column>
      <el-table-column label="ФИО сотрудника" prop="full_name"></el-table-column>
      <el-table-column label="Должность" prop="position"></el-table-column>
      <el-table-column label="Личный номер сотрудника" prop="persnum"></el-table-column>
      <el-table-column label="Месяц" prop="month"></el-table-column>
      <el-table-column label="Оклад, руб" prop="current_salary"></el-table-column>
      <el-table-column label="Целевой % годового бонуса" prop="year_bonus_percent"></el-table-column>
      <el-table-column label="Целевой размер годового бонуса, руб. " prop="year_bonus_value"></el-table-column>
      <el-table-column label="Финансовый мультипликатор, %" prop="financial_multiplier"></el-table-column>
      <el-table-column label="Индивидуальный мультипликатор,%" prop="individual_multiplier"></el-table-column>
      <el-table-column label="Годовой бонус к выплате, руб." prop="year_bonus_to_pay"></el-table-column>

    </el-table>

    <paginator
      :paginator.sync="paginator"
      @page-changed="loadProcedureReport"
    ></paginator>

  </div>
</template>

<script>


import AddUsersDialog from "@/components/user/AddUsersDialog.vue";
import ClickToEdit from "@/components/ClickToEdit.vue";
import requestSender from "@/api/base/requestSender";
import {paginator} from "@/mixins/paginator";
import {sorter} from "@/mixins/sorter";
import Paginator from "@/components/Paginator.vue";

export default {
  name: "YearBonusTemplate",
  components: {Paginator, ClickToEdit, AddUsersDialog},
  mixins: [paginator, sorter],
  props: {
    procedureId: {type: Number, required: true},
    evaluations: {type: Object, required: true},
  },

  watch: {
    procedureId: function (newVal) {
      if (!this.procedureId) {
        return;
      }
      this.loadProcedureReport();
    },
  },
  data() {
    return {
      loading: false,
      data: {},
    }
  },

  mounted() {
    this.loadProcedureReport()
  },

  methods: {
    getEvaluationNameById(evaluationId){
      return this.evaluations[4]?.find(e=>e.id===evaluationId)?.name;
    },

    loadProcedureReport() {
      this.loading = true;

      requestSender('get', 'compensation-procedure/get-report', {
        procedure_id: this.procedureId,

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
        expand: 'historyUser, chief',
      })
        .then(({data, paginator, sorter}) => {
          this.data = data;
          this.paginatorFill(paginator);
          // this.sorterFill(sorter);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getCellClass({row, column, rowIndex, columnIndex}){
      let classes = [];
      if( ['chief.full_name', 'full_name', 'position'].includes(column.property) ){
        classes.push('left-col');
      } else{
        classes.push('centered-col');
      }

      if( ['full_name', 'year_bonus_percent', 'financial_multiplier', 'individual_multiplier', 'year_bonus_to_pay'].includes(column.property) ){
        classes.push('bold-col');
      }

      if( ['financial_multiplier', 'individual_multiplier', 'year_bonus_to_pay'].includes(column.property) ){
        classes.push('color-1-col');
      }
      if( ['year_bonus_percent'].includes(column.property) ){
        classes.push('color-2-col');
      }

      return classes.join(' ');
    },
  },


}
</script>

<style lang="scss">
@import "node_modules/element-ui/packages/theme-chalk/src/common/var.scss";
@import "@/assets/css/project-variables";

.report-rss-table{
  .centered-col{
    text-align: center !important;
  }
  .bold-col{
    font-weight: bold !important;
  }
  .color-1-col{
    background-color: #c2ddee;
  }
  .color-2-col{
    background-color: #ECECEC;
  }
}

.report-rss-header{
  th{
    color: #141414 !important;
    background-color: $--color-warning !important;
    vertical-align: top !important;
    font-weight: bold !important;
    text-align: center !important;
  }
}
</style>